require("./src/styles/global.scss");

exports.onPreRouteUpdate = ({ location, prevLocation }) => {
  if (location?.hash) return;
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "instant",
  });
};
